// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCykGkCX6OcAKpB7WuG71FQcUooRfGcqmA',
  authDomain: 'examplearn-95ffb.firebaseapp.com',
  projectId: 'examplearn-95ffb',
  storageBucket: 'examplearn-95ffb.appspot.com',
  messagingSenderId: '787866158817',
  appId: '1:787866158817:web:c1c2ef7fef5bb9228359a0',
  measurementId: 'G-MMV2RS28D2',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

// export app
export default firebaseApp;
export { firebaseApp, analytics };
