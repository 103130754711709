import { axiosInstance } from './axios';
import { setToken } from './idToken';

const createAccount = async (user, languageCode = 'en') => {
  try {
    user.name = user.displayName ? user.displayName : user.email;
    user.avatar = user.photoURL
      ? user.photoURL
      : `https://ui-avatars.com/api/?name=${user.displayName}`;

    // set authentication ID token in axios header
    const idToken = await user.getIdToken();
    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${idToken}`;

    // store ID token as a cookie
    setToken(idToken);

    await axiosInstance.post('/users', {
      email: user.email,
      name: user.name,
      avatar: user.avatar,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// update user account and only update lastAccessedTime give a user
const updateAccount = async (user) => {
  try {
    // set authentication ID token in axios header
    const idToken = await user.getIdToken();
    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${idToken}`;

    // store ID token as a cookie
    setToken(idToken);

    const response = await axiosInstance.put(`/users/${user.email}`, {
      lastAccessedTime: new Date().toISOString(),
    });
  } catch (error) {
    console.log(error);
  }
};

export default createAccount;
export { createAccount, updateAccount };
