import { createContext, useState, useEffect } from 'react';
import SplashScreen from 'components/SplashScreen';
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signOut,
} from 'firebase/auth';
import { createAccount, updateAccount } from 'utils/user';
import { setToken, removeToken } from 'utils/idToken';
import { isTimeDifferenceGreaterThanOneDay } from 'utils/timeDifference';

const initialAuthState = {
  isAuthenticated: false,
  initializing: true,
  user: null,
  role: 'guest',
};

const ADMIN_EMAILS = [
  'qianghaoqiang@gmail.com',
  'soto26938@gmail.com',
  'tsikerdekis@gmail.com',
];

const AuthContext = createContext({
  ...initialAuthState,
  logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const auth = getAuth();
  const [state, setState] = useState(initialAuthState);

  // set user role manually
  const setUserRole = (user) => {
    if (ADMIN_EMAILS.includes(user.email)) {
      return 'admin';
    } else {
      return 'user';
    }
  };

  const logout = () => {
    const auth = getAuth();
    return signOut(auth);
  };

  const setLogin = (user) => {
    // set context state
    setState({
      isAuthenticated: true,
      initializing: false,
      user: user,
      role: setUserRole(user),
    });
  };

  const setLogout = () => {
    // set context state
    setState({
      isAuthenticated: false,
      initializing: false,
      user: null,
      role: 'guest',
    });

    // remove ID token from cookie
    removeToken();
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (
          !isTimeDifferenceGreaterThanOneDay(
            user.metadata.createdAt,
            user.metadata.lastLoginAt
          )
        ) {
          await createAccount(user);
        } else {
          await updateAccount(user);
        }
        setLogin(user);
      } else {
        setLogout();
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdToken();
        // store ID token as a cookie
        setToken(idToken);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (state.initializing) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
