import { useAuth } from 'hooks';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';

// the parameter user is from firebase auth
// the parameter is different the user data in our database
const useUser = () => {
  const { user } = useAuth();
  // requires authentication
  // update this function later
  const fetchUser = async () => {
    if (!user) return null;

    await setAuthToken(user);
    const response = await axiosInstance.get(`/users/${user.email}`);
    const data = await response.data.result;
    return data;
  };

  const { data } = useQuery({
    queryKey: ['getUser', user ? user.email : null],
    queryFn: () => fetchUser(),
  });

  return data;
};

export default useUser;
export { useUser };
