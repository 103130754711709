import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, ListItem } from "@mui/material";

const NavItem = ({ children, depth, href, title }) => {
  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        sx={{ display: "block", paddingTop: 0, paddingBottom: 0 }}
        disableGutters
      >
        <Button
          sx={{
            color: (theme) => theme.palette.text.secondary,
            padding: "10px 8px",
            justifyContent: "flex-start",
            textTransform: "none",
            letterSpacing: 0,
            width: "100%",
          }}
          style={style}
        >
          {title}
        </Button>
        {children}
      </ListItem>
    );
  }

  return (
    <ListItem
      sx={{
        color: (theme) => theme.palette.text.secondary,
        padding: "10px 8px !important",
        justifyContent: "flex-start  !important",
        textTransform: "none  !important",
        letterSpacing: "0px  !important",
        width: "100%  !important",
        fontWeight: (theme) => theme.typography.fontWeightRegular,
      }}
      disableGutters
    >
      <Button
        sx={{
          color: (theme) => theme.palette.text.secondary,
          padding: "10px 8px",
          justifyContent: "flex-start",
          textTransform: "none",
          letterSpacing: 0,
          width: "100%",
          "&:active": {
            color: (theme) => theme.palette.secondary.main,
            "& $title": {
              fontWeight: (theme) => theme.typography.fontWeightMedium,
            },
            "& $icon": {
              color: (theme) => theme.palette.secondary.main,
            },
          },
        }}
        component={RouterLink}
        style={style}
        to={href}
      >
        {title}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default NavItem;
