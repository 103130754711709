import { Suspense, Fragment, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import SplashScreen from './components/SplashScreen';
import AuthGuard from './components/guards/AuthGuard';
import AdminGuard from './components/guards/AdminGuard';
import GuestGuard from './components/guards/GuestGuard';
import DashboardLayout from './layouts/DashboardLayout';
import LandingLayout from './layouts/LandingLayout';
import SimpleDashboardLayout from './layouts/SimpleDashBoardLayout';
import LegalLayout from './layouts/LegalLayout';

const renderRoutes = (routes = []) => {
  return routes.map((route) => {
    // routes with children
    if (route.children) {
      let Layout = route.element ? route.element : Fragment;
      let Guard = route.guard ? route.guard : Fragment;
      route.element = (
        <Guard>
          <Layout />
        </Guard>
      );

      for (let i = 0; i < route.children.length; i++) {
        let Element = route.children[i].element;
        let Guard = route.children[i].guard
          ? route.children[i].guard
          : Fragment;

        route.children[i].element = (
          <Guard>
            <Suspense fallback={<SplashScreen />}>
              <Element />
            </Suspense>
          </Guard>
        );
      }
    } else {
      // routes without children
      let Element = route.element;
      let Guard = route.guard ? route.guard : Fragment;

      route.element = (
        <Guard>
          <Suspense fallback={<SplashScreen />}>
            <Element />
          </Suspense>
        </Guard>
      );
    }

    return route;
  });
};

const landingPages = [
  {
    element: LandingLayout,
    children: [
      {
        path: '/',
        element: lazy(() => import('./views/landing')),
      },
    ],
  },
];

const otherPages = [
  {
    element: SimpleDashboardLayout,
    children: [
      {
        guard: GuestGuard,
        path: '/signin',
        element: lazy(() => import('./views/auth/SigninView')),
      },
      {
        guard: GuestGuard,
        path: '/signup',
        element: lazy(() => import('./views/auth/SignupView')),
      },
      {
        path: '/projects',
        element: lazy(() => import('./views/project/ProjectBrowseView')),
      },
      {
        path: '/projects/:id',
        element: lazy(() => import('./views/project/ProjectDetailsView')),
      },
      {
        path: '/projects/:id/solutions',
        element: lazy(() => import('./views/solution/ProjectSolutionsView')),
      },
      {
        path: '/solutions',
        element: lazy(() => import('./views/solution/SolutionBrowseView')),
      },
      {
        path: '/solutions/:id',
        element: lazy(() => import('./views/solution/SolutionDetailsView')),
      },
      {
        guard: AuthGuard,
        path: '/projects/:projectId/solutions/:solutionId',
        element: lazy(() => import('./views/solution/SolutionFormView')),
      },
      {
        guard: AdminGuard,
        path: '/admin',
        element: lazy(() => import('./views/admin/ProjectBrowseView')),
      },
      {
        guard: AdminGuard,
        path: '/admin/:projectId?',
        element: lazy(() => import('./views/admin/ProjectDetailsView')),
      },
      {
        path: '/support',
        element: lazy(() => import('./views/support/Home')),
      },
      {
        path: '/support/faq',
        element: lazy(() => import('./views/support/Faq')),
      },
      {
        path: '/support/contact',
        element: lazy(() => import('./views/support/Contact')),
      },
      // {
      //   path: '/notAuthorized',
      //   element: lazy(() => import('./views/errors/NotAuthorized')),
      // },
      // {
      //   path: '/error',
      //   element: lazy(() => import('./views/errors/UnknownError')),
      // },
    ],
  },
  {
    element: DashboardLayout,
    guard: AuthGuard,
    children: [
      {
        path: '/home',
        element: lazy(() => import('./views/dashboard/MyProjectsView')),
      },
      {
        path: '/mysolutions',
        element: lazy(() => import('./views/dashboard/MySolutionsView')),
      },
      {
        path: '/mylikes',
        element: lazy(() => import('./views/dashboard/MyLikesView')),
      },
    ],
  },
  {
    element: LegalLayout,
    children: [
      {
        path: '/legal',
        element: lazy(() => import('./views/legal')),
      },
      {
        path: '/legal/:id',
        element: lazy(() => import('./views/legal')),
      },
    ],
  },
  // {
  //   element: ArticleLayout,
  //   children: [
  //     {
  //       path: '/support/guide',
  //       element: lazy(() => import('./views/support/Guide')),
  //     },
  //     {
  //       path: '/support/guide/:id',
  //       element: lazy(() => import('./views/support/Article')),
  //     },
  //   ],
  // },
  {
    path: '/logout',
    element: lazy(() => import('./views/auth/LogoutView')),
  },
];

const wildcard = {
  element: SimpleDashboardLayout,
  children: [
    {
      path: '*',
      element: lazy(() => import('./views/errors/NotFoundView')),
    },
  ],
};

const routes = [
  ...landingPages,
  ...otherPages,
  {
    path: '/notFound',
    element: lazy(() => import('./views/errors/NotFoundView')),
  },
  wildcard,
];

const router = createBrowserRouter(renderRoutes(routes));

export default router;
