import axios from 'axios';
import { getToken, setToken } from './idToken';
import { getAuth } from 'firebase/auth';

/* axios that requires authentication */
// config axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

// request interceptor
axiosInstance.interceptors.request.use(
  (req) => {
    if (axiosInstance.defaults.headers.common['Authorization']) return req;
    else throw new Error('Authorization token not found');
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!!error.response && error.response.status === 404) {
      return error;
    } else if (!!error.response && error.response.status === 403) {
      return error;
    } else if (!!error.response && error.response.status === 400) {
      return error;
    } else {
      return error;
    }
  }
);

// set auth token function
const setAuthToken = async (user = null) => {
  let token = getToken();
  if (!token && user) {
    token = await user.getIdToken();
    setToken(token);
  } else if (!token && !user) {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      token = await currentUser.getIdToken();
      setToken(token);
    } else {
      throw new Error('Authorization token not found');
    }
  }

  //applying token
  if (token)
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

/* axios that does not requires authentication */
// config axios instance
const axiosInstanceWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});
// axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

// response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (!!error.response && error.response.status === 404) {
      return error;
    } else if (!!error.response && error.response.status === 403) {
      console.log('We got a 403 error');

      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        let token = await currentUser.getIdToken();
        setToken(token);
        console.log('token refreshed');
      }
      return error;
    } else if (!!error.response && error.response.status === 400) {
      return error;
    } else {
      return error;
    }
  }
);

// export the instance
export { axios, axiosInstance, axiosInstanceWithoutAuth, setAuthToken };
