import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Link,
  Toolbar,
  IconButton,
  SvgIcon,
  Typography,
} from '@mui/material';
import Logo from 'components/Logo';
import MenuIcon from '@mui/icons-material/Menu';
import AppBarCustomized from './AppBarCustomized';

const MenuAppBar = ({ user, handles, menu = false }) => {
  const location = useLocation();

  if (menu)
    return (
      <AppBarCustomized>
        <Toolbar sx={{ minHeight: 64 }}>
          <IconButton
            sx={{
              display: { xs: 'block', lg: 'none' },
            }}
            color='inherit'
            onClick={handles.onMobileNavOpen}
          >
            <SvgIcon fontSize='small'>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          <Logo sx={{ display: { xs: 'block', md: 'none' } }} />
          <Box ml={2} flexGrow={1}>
            {' '}
            <Typography
              variant='h4'
              componment='div'
              sx={{ float: 'right', marginRight: 4 }}
            >
              <Link
                to='/solutions'
                component={RouterLink}
                sx={{
                  placeItems: 'center',
                  position: 'relative !important',
                  color: 'white !important',
                  textDecoration: 'none !important',
                }}
              >
                Solutions
              </Link>
            </Typography>
            <Typography
              variant='h4'
              componment='div'
              sx={{ float: 'right', marginRight: 4 }}
            >
              <Link
                to='/projects'
                component={RouterLink}
                sx={{
                  placeItems: 'center',
                  position: 'relative !important',
                  color: 'white !important',
                  textDecoration: 'none !important',
                }}
              >
                Projects
              </Link>
            </Typography>
          </Box>
          <Box>
            <Typography variant='h4' align='right'>
              {location.pathname === '/signin' ? (
                <Link
                  to='/signup'
                  component={RouterLink}
                  sx={{
                    placeItems: 'center',
                    position: 'relative !important',
                    color: 'white !important',
                    textDecoration: 'none !important',
                  }}
                >
                  Sign Up
                </Link>
              ) : (
                <Link
                  to='/signin'
                  component={RouterLink}
                  sx={{
                    placeItems: 'center',
                    position: 'relative !important',
                    color: 'white !important',
                    textDecoration: 'none !important',
                  }}
                >
                  Sign in
                </Link>
              )}
            </Typography>
          </Box>
        </Toolbar>
      </AppBarCustomized>
    );

  return (
    <AppBarCustomized>
      <Toolbar sx={{ minHeight: 64 }}>
        <Logo sx={{ display: { xs: 'block', md: 'none' } }} />
        <Box ml={3} flexGrow={1}>
          <Typography
            variant='h4'
            componment='div'
            sx={{ float: 'right', marginRight: 4 }}
          >
            <Link
              to='/solutions'
              component={RouterLink}
              sx={{
                placeItems: 'center',
                position: 'relative !important',
                color: 'white !important',
                textDecoration: 'none !important',
              }}
            >
              Solutions
            </Link>
          </Typography>
          <Typography
            variant='h4'
            componment='div'
            sx={{ float: 'right', marginRight: 4 }}
          >
            <Link
              to='/projects'
              component={RouterLink}
              sx={{
                placeItems: 'center',
                position: 'relative !important',
                color: 'white !important',
                textDecoration: 'none !important',
              }}
            >
              Projects
            </Link>
          </Typography>
        </Box>
        <Box>
          <Typography variant='h4' align='right'>
            {location.pathname === '/signin' ? (
              <Link
                to='/signup'
                component={RouterLink}
                sx={{
                  placeItems: 'center',
                  position: 'relative !important',
                  color: 'white !important',
                  textDecoration: 'none !important',
                }}
              >
                Sign up
              </Link>
            ) : (
              <Link
                to='/signin'
                component={RouterLink}
                sx={{
                  placeItems: 'center',
                  position: 'relative !important',
                  color: 'white !important',
                  textDecoration: 'none !important',
                }}
              >
                Sign in
              </Link>
            )}
          </Typography>
        </Box>
      </Toolbar>
    </AppBarCustomized>
  );
};

MenuAppBar.propTypes = {
  user: PropTypes.object,
  handles: PropTypes.object,
  t: PropTypes.func,
};

export default MenuAppBar;
