import { Outlet } from "react-router-dom";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import useAuth from "hooks/useAuth";
import TopBar from "components/TopBar";

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: "100%",
  flex: "1 1 auto",
}));

const SimpleDashboardLayout = () => {
  const { user, logout } = useAuth();

  // top bar open state & function
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const onMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  return (
    <Root>
      <TopBar
        user={user}
        handles={{ onMobileNavOpen: onMobileNavOpen, logout: logout }}
      />
      <div style={{ top: 64 }}>
        <Outlet />
      </div>
    </Root>
  );
};

export default SimpleDashboardLayout;
