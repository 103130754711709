import { useQuery } from '@tanstack/react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';
import { useAuth } from 'hooks';

const useMySolutions = () => {
  const { user } = useAuth();

  const fetchMySolutions = async () => {
    setAuthToken();
    const response = await axiosInstance.get(`users/${user.email}/solutions`);
    const data = await response.data.result;
    return data;
  };

  const { data } = useQuery({
    queryKey: ['getMySolutions', user.email],
    queryFn: () => fetchMySolutions(),
  });

  return data;
};

export default useMySolutions;
export { useMySolutions };
