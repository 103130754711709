import firebaseApp from './lib/firebase'; // necessary evil to initialize firebase
import { RouterProvider } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWebTheme } from './theme';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './contexts/FirebaseAuthContext';
import router from './routes';
import { THEMES } from './constants';
import './css/App.css';

// Create a react query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default to 120 seconds
      staleTime: 10000 * 120,
    },
  },
});

const App = () => {
  const theme = createWebTheme({
    direction: 'ltr',
    responsiveFontSizes: true,
    theme: THEMES.LIGHT,
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3}>
          <AuthProvider>
            <PerfectScrollbar
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              <RouterProvider router={router} />
            </PerfectScrollbar>
          </AuthProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
