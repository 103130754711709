import { useInfiniteQuery } from '@tanstack/react-query';
import { axiosInstanceWithoutAuth } from 'utils/axios';
import moment from 'moment';

const useSolutions = (order = 'default') => {
  const fetchSolutions = async ({ pageParam = 0 }) => {
    const response = await axiosInstanceWithoutAuth.get(
      `/solutions?pageParam=${pageParam}`
    );
    const data = await response.data.result;
    return data;
  };

  // infinite loading
  const infiniteLoading = useInfiniteQuery({
    queryKey: ['getSolutions', order],
    queryFn: fetchSolutions,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageParam;
    },
    select: (data) => {
      let result = [];
      data.pages.forEach((page) => {
        result.push(...page);
      });

      result.sort((a, b) => {
        if (order === 'default') {
          return 0;
        } else if (order === 'bookmark') {
          return b.bookmars.length - a.bookmarks.length;
        } else if (order === 'time') {
          const aTime = moment(a.time);
          const bTime = moment(b.time);
          return bTime.isBefore(aTime);
        }
      });
      return result;
    },
  });

  return infiniteLoading;
};

export default useSolutions;
export { useSolutions };
