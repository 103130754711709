import { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const NavItem = ({
  children,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
}) => {
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        sx={{ display: "block", paddingTop: 0, paddingBottom: 0 }}
        disableGutters
        key={title}
      >
        <Button
          sx={{
            color: (theme) => theme.palette.text.secondary,
            padding: "10px 8px",
            justifyContent: "flex-start",
            textTransform: "none",
            letterSpacing: 0,
            width: "100%",
          }}
          onClick={handleToggle}
          style={style}
        >
          {Icon && (
            <Icon
              sx={{
                display: "flex",
                alignItems: "center",
                marginRight: (theme) => theme.spacing(1),
              }}
              size="20"
            />
          )}
          <span style={{ marginRight: "auto" }}>
            { title }
          </span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      sx={{ display: "flex", paddingTop: 0, paddingBottom: 0 }}
      disableGutters
      key={title}
    >
      <Button
        sx={{
          color: (theme) => theme.palette.text.secondary,
          padding: "20px 8px",
          justifyContent: "flex-start",
          textTransform: "none",
          letterSpacing: 0,
          width: "100%",
          "&:active": {
            color: (theme) => theme.palette.secondary.main,
            "& $title": {
              fontWeight: (theme) => theme.typography.fontWeightMedium,
            },
            "& $icon": {
              color: (theme) => theme.palette.secondary.main,
            },
          },
        }}
        component={RouterLink}
        style={style}
        to={href}
      >
        {Icon && (
          <Icon
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: (theme) => theme.spacing(1),
            }}
            size="20"
          />
        )}
        <span style={{ paddingLeft: 16, marginRight: "auto" }}>
          { title }
        </span>
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
