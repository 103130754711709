import { Avatar, Box, Typography } from "@mui/material";

const UserInfo = ({ user }) => {
  return (
    <>
      <Box display="flex" justifyContent="center">
        <Avatar
          alt="User"
          sx={{ width: 64, height: 64 }}
          src={
            user.photoURL
              ? user.photoURL
              : `https://ui-avatars.com/api/?name=${user.displayName}`
          }
        />
      </Box>
      <Box mt={2} textAlign="center">
        <Typography variant="h5" color="textPrimary">
          {user.displayName}
        </Typography>
      </Box>
    </>
  );
};

export default UserInfo;