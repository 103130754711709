import Cookies from 'js-cookie';

export const getToken = () => {
  const idToken = Cookies.get('examplearn-token');
  // console.log(idToken);
  return idToken;
};

export const setToken = (idToken) => {
  const lastToken = Cookies.get('examplearn-token');
  if (lastToken && lastToken === idToken) return;
  Cookies.set('examplearn-token', idToken, { path: '/', expires: 1 / 24 });
};

export const removeToken = () => {
  Cookies.remove('examplearn-token');
};