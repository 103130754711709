import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';
import TopBar from 'components/TopBar';
import FootBar from 'components/FootBar';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

const SimpleDashboardLayout = () => {
  const { user, logout } = useAuth();

  // top bar open state & function
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const onMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  return (
    <Root>
      <TopBar
        user={user}
        handles={{ onMobileNavOpen: onMobileNavOpen, logout: logout }}
      />
      <div style={{ flex: '1 1 auto', paddingTop: 64 }}>
        <Outlet />
      </div>
      <FootBar style={{ width: '100%', minHeight: '300px' }} />
    </Root>
  );
};

export default SimpleDashboardLayout;
