import PropTypes from "prop-types";
import MenuAppBar from "./components/MenuAppBar";
import MenuUserAppBar from "./components/MenuUserAppBar";

const TopBar = ({ user, handles, menu=false }) => { 
  if (user)
    return (
      <MenuUserAppBar user={user} handles={handles} menu={menu} />
    );

  return <MenuAppBar user={user} handles={handles} menu={menu} />;
};

TopBar.propTypes = {
  user: PropTypes.object,
  handles: PropTypes.object,
  Trans: PropTypes.func,
};

export default TopBar;
