import { useQuery } from '@tanstack/react-query';
import useLegalNavigation from './useLegalNavigation';

const useLegalArticle = (id) => {
  // fetch meta data
  const navData = useLegalNavigation();

  // fetch article
  const fetchLegalArticle = async () => {
    if (!id) return;
    let response = await fetch(`/static/assets/legal/${id}.md`);
    const data = await response.text();
    return data;
  };

  const { data } = useQuery({
    queryKey: ['getLegalArticle', id],
    queryFn: () => fetchLegalArticle(),
    select: (data) => {
      if (!data) return '';
      const article = navData.items.filter((item) => item.id === id)[0];
      article.content = data;
      return article;
    },
  });

  return data;
};

export default useLegalArticle;
export { useLegalArticle };
