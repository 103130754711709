import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';

const AdminGuard = ({ children }) => {
  const { isAuthenticated, role } = useAuth();

  if (!isAuthenticated) return <Navigate to='/signin' />;
  else if (isAuthenticated && role === 'user') return <Navigate to='/home' />;
  else return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminGuard;
