import { useQuery } from '@tanstack/react-query';

const useFaq = (typeId = 'all') => {
  // fetch faq
  const fetchFaq = async () => {
    let response = await fetch('/static/assets/faq.json');
    const data = await response.json();
    return data.faq;
  };

  const { data } = useQuery({
    queryKey: ['getFaq', typeId],
    queryFn: () => fetchFaq(),
    select: (data) => {
      // prepare an array that contains typeIDs and typeNames
      let types = [];
      let typeIds = [];

      // get all type IDs and names
      if (typeId !== 'all') {
        for (let i = 0; i < data.length; i++) {
          if (data[i].type.id === typeId) {
            types.push({
              id: typeId,
              name: data[i].type.name,
            })
            break;
          } 
        }
      } else {
        data.forEach((item) => {
          if (!typeIds.includes(item.type.id)) {
            types.push({
              id: item.type.id,
              name: item.type.name,
            });
          }
          typeIds.push(item.type.id);
        });
      }

      // create result
      const result = [];
      types.forEach((type) => {
        const items = [];
        data.forEach((item) => {
          if (item.type.id === type.id) {
            items.push(item);
          }
        });
        result.push({ type, items });
      });

      return result;
    },
  });

  console.log(data);

  return data;
};

export default useFaq;
export { useFaq };
