import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import NavBar from './NavBar';
import TopBar from 'components/TopBar';
import useAuth from 'hooks/useAuth';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '100%',
  flex: '1 1 auto',
}));

const ContentDiv = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  paddingBottom: 64,
  paddingTop: 128,
  [theme.breakpoints.up('xl')]: {
    paddingLeft: 256,
  },
}));

const DashboardLayout = () => {
  const { user, logout } = useAuth();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const onMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const onMobileClose = () => {
    setMobileNavOpen(false);
  };

  return (
    <Root>
      <TopBar
        user={user}
        handles={{ onMobileNavOpen: onMobileNavOpen, logout: logout }}
        menu={true}
      />
      <NavBar
        user={user}
        openMobile={isMobileNavOpen}
        handles={{ onMobileClose: onMobileClose }}
      />
      <ContentDiv>
        <Outlet />
      </ContentDiv>
    </Root>
  );
};

export default DashboardLayout;
