import { useQuery } from '@tanstack/react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';
import { useAuth } from 'hooks';

const useMyBookmarks = () => {
  const { user } = useAuth();

  const fetchMyBookmarks = async () => {
    setAuthToken();
    const response = await axiosInstance.get(`users/${user.email}/bookmarks`);
    const data = await response.data.result;
    return data;
  };

  const { data } = useQuery({
    queryKey: ['getMyBookmarks', user.email],
    queryFn: () => fetchMyBookmarks(),
  });

  return data;
};

export default useMyBookmarks;
export { useMyBookmarks };
