import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useAuth } from 'hooks';

const Account = ({ user, handles }) => {
  const { role } = useAuth();

  const navigate = useNavigate();
  const ref = useRef(null);

  const logout = handles.logout;

  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  const handleGoToDashboard = () => {
    handleClose();
    navigate('/home');
  }

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt='User'
          sx={{
            height: 32,
            width: 32,
            marginRight: (theme) => theme.spacing(1),
          }}
          src={
            user.photoURL
              ? user.photoURL
              : `https://ui-avatars.com/api/?name=${user.displayName}`
          }
        />
        <Hidden smDown>
          <Typography variant='h6' color='inherit'>
            {role === 'admin' && 'Admin'}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        sx={{ width: 200 }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleGoToDashboard}>Dashboard</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
