/* eslint-disable no-use-before-define */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, List } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NavItem from './NavItem';
import useLegalNavigation from 'hooks/useLegalNavigation';

const renderNavItems = ({ items, depth = 0 }) => {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, depth }), [])}
    </List>
  );
};

const reduceChildRoutes = ({ acc, item, depth = 0 }) => {
  if (item.items) {
    acc.push(
      <NavItem depth={depth} key={item.href} title={item.title}>
        {renderNavItems({
          items: item.items,
          depth: depth + 1,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        key={item.href}
        title={item.title}
      />
    );
  }

  return acc;
};

const NavBar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  const section = useLegalNavigation();

  console.log(section);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <List>
            {section && renderNavItems({
              items: section.items,
              depth: 0,
            })}
          </List>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor='left'
        sx={{ display: { xs: 'block', lg: 'none' } }}
        PaperProps={{
          sx: {
            width: 256,
            top: '65px  !important',
          },
        }}
        onClose={onMobileClose}
        open={openMobile}
        variant='temporary'
      >
        {content}
      </Drawer>

      <Drawer
        anchor='left'
        sx={{
          display: { xs: 'none', lg: 'block' },
        }}
        PaperProps={{
          sx: {
            width: '18%  !important',
            top: '65px  !important',
            height: 'calc(100% - 65px) !important',
          },
        }}
        open
        variant='persistent'
      >
        {content}
      </Drawer>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
