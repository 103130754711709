import { useQuery } from '@tanstack/react-query';
import { axiosInstanceWithoutAuth } from 'utils/axios';

const useProject = (id) => {
  const fetchSingleProject = async () => {
    if (id === 'new') return null;
    const response = await axiosInstanceWithoutAuth.get(`/projects/${id}`);
    const data = await response.data.result;
    return data;
  };

  const { data } = useQuery({
    queryKey: ['getProject', id],
    queryFn: () => fetchSingleProject(),
  });

  return data;
};

export default useProject;
export { useProject };
