import { useInfiniteQuery } from '@tanstack/react-query';
import { axiosInstanceWithoutAuth } from 'utils/axios';

const useProjectSolutions = (id) => {
  const fetchProjectSolutions = async ({ pageParam = 0 }) => {
    const response = await axiosInstanceWithoutAuth.get(
      `/projects/${id}/solutions?pageParam=${pageParam}`
    );

    return response.data.result;
  };

  // infinite loading
  const infiniteLoading = useInfiniteQuery({
    queryKey: ['getProjectSolutions', id],
    queryFn: fetchProjectSolutions,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageParam;
    },
  });

  return infiniteLoading;
};

export default useProjectSolutions;
export { useProjectSolutions };
