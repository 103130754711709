/* eslint-disable no-use-before-define */
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
} from '@mui/material';
import { AiFillFolderAdd } from 'react-icons/ai';
import { AiFillHeart } from 'react-icons/ai';
import { FaKey } from 'react-icons/fa';
import Logo from 'components/Logo';
import useIsMountedRef from 'hooks/useIsMountedRef';
import NavItem from './NavItem';
import UserInfo from './UserInfo';

const sections = [
  {
    items: [
      {
        title: 'My projects',
        icon: AiFillFolderAdd,
        href: '/home',
      },
      {
        title: 'My solutions',
        icon: FaKey,
        href: '/mysolutions',
      },
      {
        title: 'My likes',
        icon: AiFillHeart, 
        href: '/mylikes',
      },
    ],
  },
];

const renderNavItems = ({ items, pathname, depth = 0 }) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(
      {
        path: item.href,
      },
      pathname
    );

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
};

const NavBar = ({ user, openMobile, handles }) => {
  const location = useLocation();
  const isMountedRef = useIsMountedRef();

  const onMobileClose = handles.onMobileClose;

  useEffect(() => {
    if (isMountedRef.current && openMobile) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedRef, location.pathname]);

  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
        <Hidden lgUp>
          <Box p={2} display='flex' justifyContent='center'>
            <Logo user={user} />
          </Box>
        </Hidden>
        <Box p={2}>
          <UserInfo user={user} />
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section, key) => (
            <List
              key={key}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                depth: 0,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius='borderRadius' bgcolor='background.dark'>
            <Typography variant='h6' color='textPrimary'>
              Need Help?
            </Typography>
            <Link
              variant='subtitle1'
              color='secondary'
              component={RouterLink}
              to='/support'
              sx={{ textDecoration: 'none !important' }}
            >
              Check our support center
            </Link>
          </Box>
        </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor='left'
        sx={{ display: { xs: 'block', xl: 'none' } }}
        PaperProps={{
          sx: {
            width: 256,
            // top: "65px  !important",
          },
        }}
        onClose={onMobileClose}
        open={openMobile}
        variant='temporary'
      >
        {content}
      </Drawer>
      <Drawer
        anchor='left'
        sx={{
          display: { xs: 'none', xl: 'block' },
        }}
        PaperProps={{
          sx: {
            width: '256px !important',
            top: '64px !important',
            height: 'calc(100% - 64px) !important',
          },
        }}
        open
        variant='persistent'
      >
        {content}
      </Drawer>
    </>
  );
};

export default NavBar;
