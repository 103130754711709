import { useQuery } from '@tanstack/react-query';
import { axiosInstanceWithoutAuth } from 'utils/axios';

const useSolution = (id) => {
  const fetchSolution = async () => {
    if (id === 'new') return null;
    const response = await axiosInstanceWithoutAuth.get(`/solutions/${id}`);
    const data = await response.data.result;
    return data;
  };

  // return data
  const { data } = useQuery({
    queryKey: ['getSolution', id],
    queryFn: fetchSolution,
  });

  return data;
};

export default useSolution;
export { useSolution };
