import { useInfiniteQuery } from '@tanstack/react-query';
import { axiosInstance, axiosInstanceWithoutAuth, setAuthToken } from 'utils/axios';

const orderOptions = {
  Beginner: 0,
  Intermediate: 1,
  Advanced: 2,
};

const useProjects = (order = 'default', authentication = false) => {
  const fetchProjects = async ({ pageParam = 0 }) => {
    let response;
    if (authentication) {
      setAuthToken();
      response = await axiosInstance.get(
        `/projects-protected?pageParam=${pageParam}`
      );
    } else {
      response = await axiosInstanceWithoutAuth.get(
        `/projects?pageParam=${pageParam}`
      );
    }
    const data = await response.data.result;
    return data;
  };

  // infinite loading
  const infiniteLoading = useInfiniteQuery({
    queryKey: ['getProjects', order, authentication],
    queryFn: fetchProjects,
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageParam;
    },
    select: (data) => {
      let result = [];
      data.pages.forEach((page) => {
        result.push(...page);
      });

      result.sort((a, b) => {
        if (order === 'default') {
          return 0;
        } else if (order === 'easy') {
          return orderOptions[a.level] - orderOptions[b.level];
        } else if (order === 'hard') {
          return orderOptions[b.level] - orderOptions[a.level];
        }
      });
      return result;
    },
  });

  return infiniteLoading;
};

export default useProjects;
export { useProjects };
