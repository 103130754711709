import { useQuery } from '@tanstack/react-query';
import { axiosInstance, setAuthToken } from 'utils/axios';
import { useAuth } from 'hooks';

const useMyProjects = () => {
  const { user } = useAuth();

  const fetchMyProjects = async () => {
    setAuthToken();
    const response = await axiosInstance.get(`users/${user.email}/projects`);
    const data = await response.data.result;
    return data;
  };

  const { data } = useQuery({
    queryKey: ['getMyProjects', user.email],
    queryFn: () => fetchMyProjects(),
  });

  console.log(data);

  return data;
};

export default useMyProjects;
export { useMyProjects };
