import { useQuery } from '@tanstack/react-query';

const useLegalNavigation = () => {
  // fetch faq
  const fetLegalNavigation = async () => {
    let response = await fetch('/static/assets/legal/legalNavigation.json');
    const data = await response.json();
    return data;
  };

  const { data } = useQuery({
    queryKey: ['getLegalNavigation'],
    queryFn: () => fetLegalNavigation(),
  });

  return data;
};

export default useLegalNavigation;
export { useLegalNavigation };
