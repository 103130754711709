import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';

const GuestGuard = ({ children }) => {
  // navigation
  const location = useLocation();
  const { next } = location.state || { next: '/home' };

  // authentication
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) return <Navigate to={next} />;
  else return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
