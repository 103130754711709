import { AppBar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THEMES } from 'constants';

const AppBarCustomized = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  ...(theme.name === THEMES.LIGHT
    ? {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
      }
    : {}),
  ...(theme.name === THEMES.ONE_DARK
    ? {
        backgroundColor: theme.palette.background.default,
      }
    : {}),
}));

export default AppBarCustomized;
